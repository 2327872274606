import axios from 'axios';


class AppDevice {
    static ServerUrl = '';
    static ExternalServer = '';
    static InstitutionName = '';
    static sharetime = 0;
    static phoneRef = '';
    static async DeviceInit(){
        let config = (await axios.get('/configuracion.json')).data;
        this.ServerUrl = config.ServidorDicom;
        this.ExternalServer = config.NombreInstitucion;
        this.InstitutionName = config.ServicorExterno;
        this.sharetime = config.sharetime;
        this.phoneRef = config.ref;
    }
    static toJson(){
        return {
            ServidorDicom:this.ServerUrl,
            NombreInstitucion:this.ExternalServer,
            ServicorExterno:this.InstitutionName,
            sharetime:this.sharetime,
            ref:this.phoneRef
        }
    }
    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}

export default AppDevice;