import React, { useEffect, useState } from "react";
import Cabeza from "./subcomp/Cabeza";
import SideBar from "./subcomp/SideBar";
import Drawer from "./subcomp/Drawer";
import Pies from "./subcomp/Pies";
import Listado from "./subcomp/Listado";
import Dialog from "./subcomp/Dialog";

//import Estado from "./ClasesFunsiones/Estudio"
import StudyItem from "../../Class/study";

import moment from "moment"

//import Consultas from "./ClasesFunsiones/consultas";

import "./Main.scss";
//import Estudio from "./ClasesFunsiones/Estudio";


let MainConf = localStorage.getItem("MainConf")
if(MainConf){
  MainConf=JSON.parse(MainConf)
}else{
  let a = {AutoSync:false}
  localStorage.setItem("MainConf",JSON.stringify(a))
  MainConf=a
}

function Main(props) {
  const [FiltroPaciente, setFiltroPaciente] = useState("");
  const [VerDrawer, setVerDrawer] = useState({ visible: false, componente: null });
  const [DialogStado, setDialogStado] = useState({ visible: false, componente: 0 })
  const [ListaEstudios, setListaEstudios] = useState({
    cargando: true,
    sync: true,
    items: [],
    error: false,
  });
  const [VerHerramientas, setVerHerramientas] = useState({});
  const [fechas, setfechas] = useState({
    inicio:moment().subtract(1,"d").format("YYYYMMDD"),
    final:moment().format("YYYYMMDD"),
    ready:false
  })
  const [Configuracion, setConfiguracion] = useState(MainConf)
  const [AutoSync, setAutoSync] = useState()
  const [filtromod, setfiltromod] = useState([])
  const fetchcontrol = new AbortController()
  console.log(fechas)
  let onChangaDate = (fechas_)=>{
    setfechas({...fechas,inicio:fechas_.inicio,final:fechas_.final})
  }
  let OperarDrawer = (e) => {
    setVerDrawer(e);
  };
  let SelEstudio = (e) => {
    setVerHerramientas(e);
  };
  let syncLista = () => {
    if(!ListaEstudios.sync){
      setListaEstudios({ ...ListaEstudios, sync: true });
      StudyItem.GetList(fechas,fetchcontrol.signal)
        .then((db) => {
          console.log(db);
          setListaEstudios({
            cargando: false,
            sync: false,
            items: db,
            error: false,
          });
        })
        .catch((e) => {
          setListaEstudios({
            cargando: false,
            sync: false,
            items: [],
            error: true,
          });
        });
    }
  };
  let cambiarconf = (t,e)=>{
    let a  = Configuracion
    a[t]=e
    localStorage.setItem("MainConf",JSON.stringify(a))
    setConfiguracion({...a})
  }
  let CerrarDialog = ()=>{
    setDialogStado({ visible: false, componente: null })
  }
  useEffect(() => {
    if(Configuracion.AutoSync && !ListaEstudios.cargando && !ListaEstudios.error){
      let a = setInterval(syncLista, 50000)
      setAutoSync(a);
    }
    else{
      clearInterval(AutoSync)
    }
    return () => {
      clearInterval(AutoSync)
      //cleanup
    }
  }, [Configuracion,ListaEstudios.cargando,ListaEstudios.error,ListaEstudios.items])
  useEffect(() => {
    if (!fechas.ready) return;
    StudyItem.GetList(fechas,fetchcontrol.signal)
      .then((db) => {
        setListaEstudios({
          cargando: false,
          sync: false,
          items: db,
          error: false,
        });
      })
      .catch((e) => {
        if(!fetchcontrol.signal.aborted){
          setListaEstudios({
            cargando: false,
            sync: false,
            items: [],
            error: true,
          });
        }
      });
    return () => {
      setListaEstudios({
        cargando: true,
        sync: true,
        items: [],
        error: false,
      });
      fetchcontrol.abort()
      clearInterval(AutoSync)
    };
  }, [fechas]);
  useEffect(() => {
    let filtro = window.sessionStorage.getItem("filtrosmain");
    if (filtro) {
      let a = JSON.parse(filtro);
      setfiltromod(a.mod);
      setfechas(a.fecahs);
    } else {
    setfechas({...fechas,ready:true})
    }
    return () => {
      if (fechas.ready) {
        window.sessionStorage.setItem("filtrosmain",JSON.stringify({fecahs:fechas,mod:filtromod}))
      }
    }
  }, [])
  return (
    <div id="Main">
      <Cabeza
        filtrar={(e) => {
          setFiltroPaciente(e);
        }}
        filtro={FiltroPaciente}
      />
      <SideBar
        ListaEstudios={ListaEstudios}
        onOpen={OperarDrawer}
        VerDrawer={VerDrawer}
        onSync={syncLista}
      />
      <Drawer
        activo={VerDrawer}
        onClose={() => {
          OperarDrawer({ visible: false, componente: null });
        }}
        Configuracion={Configuracion}
        onChangeConfig={cambiarconf}
        fechas={fechas}
        onChangaDate={onChangaDate}
        ListaEstudios={ListaEstudios}
        filtromod={filtromod}
        setfiltromod={setfiltromod}
      />
      <Pies
        onOpen={OperarDrawer}
        VerDrawer={VerDrawer}
        ListaEstudios={ListaEstudios}
        onSync={syncLista}
      />
      <Listado
        {...props}
        FiltroPaciente={FiltroPaciente}
        ListaEstudios={ListaEstudios}
        AbrirHerramientas={SelEstudio}
        VerHerramientas={VerHerramientas}
        filtromod={filtromod}
        onDialog={(e)=>{setDialogStado({ visible: true, componente: e })}}
        fechas={fechas}
      />
      <Dialog
        activo={DialogStado}
        paciente={VerHerramientas}
        CerrarDialog={CerrarDialog}
      />
      <div>7</div>
    </div>
  );
}

export default Main;
